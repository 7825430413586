.app {
  width: 100%;
}
.header {
  padding: 5px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  height: 55px;
}
.header-contents {
  justify-content: space-between;
  align-items: center;
}
.tltlogo {
  width: 32px;
}
.nav {
  width: 70%;
}
.nav-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.site-brand {
  display: block;
}
.site-menu {
  display: flex;
}

.site-menu li {
  margin: 0 20px;
  position: relative;
  font-family: "Sohn Bold", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.site-menu li a {
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  color: #fff;
  font-size: 14px;
}
.site-menu li:hover a,
.site-menu li:focus a {
  color: #ffdb00;
}
.footer-menu-item li:after,
.site-menu li:after {
  content: "";
  position: absolute;
  top: 100%;
  height: 2px;
  background-color: #ffdb00;
  width: 100%;
  left: 0;
  opacity: 0;
  transition: 0.3s ease;
  border-radius: 1em;
}
.footer-menu-item li:hover:after,
.site-menu li:hover:after {
  opacity: 1;
}
.site-menu li:last-child {
  margin-right: 0;
}
.hero {
  margin-top: 65px;
}
.hero-contents-container {
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 15px;
  margin-top: 100px;
}
.hero-contents-container .text-side {
  width: 600px;
  padding: 20px 0 0 0;
  margin-left: 10px;
}
.icon {
  width: 20px;
}
.hero-rating {
  align-items: center;
  color: #ffdb00;
}
.hero-rating p {
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: -2px;
}
.hero-title {
  font-size: 3.5em;
  margin: 20px 0;
  color: #21334d;
  text-transform: uppercase;
}
.hero-subtitle {
  margin-bottom: 10px;
  font-size: 16px;
  color: #21334d;
}

.text-side-cta {
  margin: 40px 0 20px 0;
  align-items: center;
}

.text-side-cta .modal-trigger {
  padding: 16px;
  border-radius: 2px;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.2);
}
.section-title {
  text-transform: uppercase;
  position: relative;
}

.section-title:after {
  content: "";
  position: absolute;
  top: 110%;
  height: 8px;
  background-color: #ffdb00;
  width: 100px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  margin: auto;
  opacity: 1;
  transition: 0.3s ease;
  border-radius: 1px;
}

.main-cta .modal-trigger {
  width: 250px;
  justify-content: center;
  font-size: 16px;
  align-items: center;
  margin-right: 30px;
}
.sub-cta .button {
  background-color: transparent;
  color: #21334d;
  box-shadow: none;
}
.sub-cta .button:hover {
  color: #ffdb00;
}
.text-side-review {
  margin-bottom: 0;
  padding: 8px;
  width: 95%;
  margin: auto;
  text-align: center;
  color: #21334d;
}
.hero-reviewer {
  font-size: 1.3em;
  text-transform: uppercase;
}
.hide-menu-item {
  display: none;
}
.hero-review {
  margin: 10px 0 10px 20px;
  font-size: 16px;
  font-style: italic;
}
.designer {
  font-size: 12px;
}
.hero-contents-container .image-side {
  width: auto;
  position: relative;
  text-align: center;
}
.background {
  position: absolute;
  width: 100%;
  height: 150px;
  z-index: 1;
  top: 40%;
  right: 0;
  border-radius: 2px;
}

.background.treasure {
  background-color: #ffdb00;
}
.cover {
  /*box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);*/
  position: relative;
  z-index: 2;
  width: 250px;
  margin: auto;
}
.cover2 {
  width: 300px;
}
.open-book {
  width: 100px;
}
.who-container {
  flex-flow: row wrap;
  align-items: baseline;
  justify-content: center;
  z-index: 2;
}
.who-card {
  width: 150px;
  text-align: center;
  margin: 20px;
}
.who {
  margin-top: 10px;
}
.who-text {
  font-size: 14px;
  margin-top: 10px;
}
.who-card .icon-side img {
  width: 50px;
  height: auto;
}

.who-card .text-side {
  margin-left: 8px;
}
.section-head.width-300 {
  width: 380px;
}
.sides-container {
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.topics-wrap {
  flex-flow: row wrap;
  align-items: baseline;
  justify-content: center;
}
.topics-wrap li {
  width: 150px;
  text-align: center;
  margin: 20px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.topics-wrap li span {
  text-align: center;
  display: block;
  width: 100%;
}

.topics-wrap li img {
  width: 20px;
  margin: 0 auto;
}

.faqs-container {
  flex-flow: row wrap;
  justify-content: space-around;
  display: flex;
}
.faqs-container li.faq {
  flex: 100% 1 1;
  max-width: 350px;
  min-width: 280px;
  margin: 0 0 32px 0;
  padding: 4px;
  border-left: 1px solid #ffdb00;
  border-right: 1px solid #ffdb00;
  border-radius: 4px;
}
.review-card {
  width: 45%;
  margin: 20px;
  background-color: #fff;
  padding: 16px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border: 1px solid #ffdb00;
}
.review {
  font-size: 1em;
}
.review-brief {
  font-size: 1.3em;
}
.background-bar {
  content: "";
  position: absolute;
  height: 200px;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.reviewer {
  align-items: center;
  border-top: 2px solid #ffdb00;
  padding-top: 10px;
}

.reviewer-img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 8px;
}
.reviews-cards-inner {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  z-index: 2;
  padding-bottom: 50px;
  flex-wrap: wrap;
}

.slider-items-container {
  height: 100%;
  width: 100%;
  padding: 5px 0;
}

.slick-track {
  display: flex !important;
  align-items: flex-end !important;
}
.slick-slide {
  display: flex !important;
  justify-content: center !important;
}
.slider-items-container .slick-arrow {
  background-color: transparent !important;
  border-radius: 1px;
  height: 30px;
  padding-top: 3px;
  width: 40px;
  top: 100%;
}
.slider-items-container .slick-arrow {
  background-color: #21334d !important;
}
.slider-items-container .slick-arrow:hover {
  background-color: #555 !important;
}

.slider-items-container .slick-next,
.slider-items-container .slick-prev {
  z-index: 2;
}
.slick-dots {
  bottom: 0 !important;
}
.carousel .slider-items-container.center-slide-arrows .slick-arrow {
  top: 45% !important;
}
.carousel .slider-items-container.hero-review-slide .slick-arrow {
  top: 50% !important;
}

.carousel .slider-items-container .slick-next {
  right: 0;
}

.carousel .slider-items-container .slick-prev {
  left: 0;
}
.slider-items-container .slick-arrow.slick-disabled:before {
  opacity: 0;
}

.slider-items-container .slick-arrow.slick-disabled {
  background-color: transparent !important;
  cursor: auto !important;
}

.footer-menu {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.footer-menu-item li a {
  text-decoration: none;
  color: #fff;
}
.footer-menu-item li:hover a {
  color: #ffdb00;
}
.footer-menu-item li {
  margin: 16px 0;
  position: relative;
  width: fit-content;
}
.footer-menu-item2 {
  text-align: right;
}
.book img {
  width: 50px;
}
.book h3 {
  color: #ffdb00;
  font-size: 25px;
  margin-bottom: 10px;
}
.book p {
  color: #ccc;
  font-size: 1em;
}
.site-book {
  text-align: center;
  width: 280px;
}
.outbooks-cards-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.outbook-card {
  padding: 40px;
  width: 42%;
  position: relative;
  margin: 20px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.outbook-card-brief {
  flex-flow: column;
}
.outbook-card-brief .out-image img {
  width: 150px;
}
.outbook-card-inner {
  padding: 20px;
  position: relative;
  z-index: 10;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
}
.outbook-card-inner.btreasure {
  background-color: #21334d;
}
.outbook-card-inner.bdaddaughter {
  background-color: #000;
}
.bookcard-body {
  margin: 20px 0;
  margin-bottom: 25px;
  font-size: 1em;
  padding: 0 20px;
}

.cta-container {
  text-align: center;
}
.book-cta {
  border-radius: 1px;
  box-shadow: 0 0 20px 2px rgb(0 0 0 / 20%);
  padding-bottom: 13px;
  width: 280px;
  font-size: 1em;
  text-transform: uppercase;
}

.book-cta.lyellow {
  background-color: #ffdb00;
  color: #21334d;
}

.book-cta.darkb {
  background-color: #21334d;
  color: #fff;
}
.book-cta.cblue {
  background-color: #50b9e9;
  color: #fff;
}
.social-container {
  justify-content: center;
  align-items: flex-start;
}
.wft-img {
  width: 100px;
}
.social-card {
  text-decoration: none;
  color: inherit;
  text-align: center;
  margin: 10px 20px;
  max-width: 200px;
}

.social-card img.social-icon {
  width: 80px;
  height: auto;
}
#about {
  background-image: url("./assets/images/background/treasure.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
#about:after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.about-inner {
  position: relative;
  z-index: 2;
}
.about-container {
  justify-content: space-around;
  align-items: flex-start;
}
.about-text-side {
  width: 50%;
  text-align: justify;
}

.about-text-side h4 {
  color: #ffdb00;
  font-size: 1.4em;
}

.about-text-side p {
  color: #fff;
}

.about-text-side .modal-contents-container p {
  color: #000;
}

.scroll {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 40px;
  right: 5px;
  text-align: center;
  z-index: 1000;
}

.scroll-button {
  color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ffdb00;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-button img {
  width: 50px;
  height: 50px;
}
#toggle-responsive-menu,
.nav-label {
  display: none;
}
.hide-menu {
  display: none;
  width: 100%;
}
.hide-menu-btn {
  color: #ffdb00;
  font-size: 24px;
  box-shadow: none;
  background-color: transparent;
  line-height: 0;
  padding: 0;
  padding-bottom: 0px;
  border: 1px solid #ffdb00;
  width: 30px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  border-radius: 0;
}
.hide-menu-btn:hover {
  color: rgba(86, 103, 122, 1);
  border: 1px solid rgba(86, 103, 122, 1);
}

.buy-cards-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.buy-card {
  display: block;
  text-decoration: none;
  color: inherit;
  text-align: center;
  flex: 150px 1 1;
  padding: 10px 10px;
  margin: 20px;
  border-radius: 2px;
  border: 2px solid #21334d;
  background-color: #fff;
}
.buy-card:hover {
  border-bottom: 2px solid #50b9e9;
}
.buy-card img {
  width: 100px;
  height: auto;
  line-height: 0;
}

.buy-card span {
  text-transform: uppercase;
}
.international a {
  margin-bottom: 20px;
  display: block;
  color: #21334d;
}

.international a img {
  width: 150px;
}

.distribution-list {
  justify-content: center;
  flex-flow: row wrap;
}

.distribution-card {
  width: 200px;
  margin: 20px;
  padding: 8px;
  border-radius: 2px;
}

.distribution-title {
  border-bottom: 2px solid #21334d;
  margin-bottom: 10px;
  text-align: center;
  color: #21334d;
}
.distribution-cta {
  color: #50b9e9;
}
.modal-title {
  font-size: 1.4em;
}
.contact-card {
  color: #fff;
  width: 200px;
  margin: 20px;
}
.contact-card h3 {
  border-bottom: 2px solid #fff;
  text-align: center;
  margin-bottom: 20px;
}

.contact-card-body p {
  font-size: 1em;
  text-align: center;
  margin-bottom: 10px;
  color: #fff;
}
.contact-card-body p a {
  color: lightgreen;
  text-decoration: none;
}
.contact-card-body p a:hover {
  color: #ffdb00;
  text-decoration: underline;
}

.bundles {
  justify-content: center;
  flex-wrap: wrap;
}
.bundle-card {
  padding: 8px;
  width: auto;
  margin: 32px;
}

.bundle-card.premium {
  width: fit-content;
}

.bundle-images-container {
  justify-content: center;
}

.bundle-images-container figure img {
  width: 150px;
  height: auto;
}
.bundle-images-container figure {
  padding: 10px;
  margin: 0;
}
.bundle-images-container figure figcaption,
.bundle-price {
  color: #ccc;
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
}

.bundle-price {
  color: white;
}
.hero-media {
  width: 50%;
  margin: auto;
}

.accepted-payments p {
  font-size: 8px;
  margin-top: 5px;
}
.accepted-payments img {
  width: 30px;
  margin: 0 2px;
}
.accepted-payments img:first-child {
  margin-left: 0;
}


.order-container h2 {
  font-size: 18px;
  border-bottom: 2px solid #000;
}

.order-container h3 {
  font-size: 16px;
  font-weight: 600;
}
.order-container h2,
.order-container h3 {
  margin: 16px;
}
.order-container div {
  text-align: center;
}
.order-container div img {
  width: 300px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  margin-top: 16px;
}
.order-container div p {
  font-size: 12px;
}
.order-container p {
  margin-bottom: 10px;
}
.order-container ul li{
  margin: 10px 0;
  list-style: decimal;
  list-style-position: inside;
}
.order-container ul li a{
  font-weight: 600;
}


@media screen and (min-width: 960px) and (max-width: 1200px) {
  .nav {
    width: 90%;
  }
  .hero-media {
    width: 70%;
  }
  .hero-contents-container .text-side {
    width: 50%;
  }

  .hero-title {
    font-size: 2.5em;
  }
  .site-menu li a {
    font-size: 14px;
  }
  .hero-subtitle {
    font-size: 14px;
  }
  .width-960 {
    width: 100%;
    max-width: 960px;
  }
  .carousel .slider-items-container.center-slide-arrows .slick-arrow {
    top: 100% !important;
  }
  .carousel .slider-items-container .slick-next {
    right: 20px;
  }

  .carousel .slider-items-container .slick-prev {
    left: 20px;
  }
  .outbook-card {
    flex-flow: column;
  }
  .outbooks-cards-container {
    align-items: end;
  }
  .text-side-review {
    width: 90%;
  }
  .carousel .slider-items-container.hero-review-slide .slick-arrow {
    top: 90% !important;
  }

  .carousel .slider-items-container.hero-review-slide .slick-next {
    right: 0;
  }

  .carousel .slider-items-container.hero-review-slide .slick-prev {
    left: 0;
  }
}

@media screen and (min-width: 800px) and (max-width: 959px) {
  .nav {
    width: 90%;
  }
  .hero-media {
    width: 80%;
  }
  .site-menu li {
    margin: 0 13px;
  }
  .bundles {
    flex-flow: column;
    flex-wrap: wrap;
    align-items: center;
  }
  .bundle-card.premium {
    margin-bottom: 30px;
  }
  .hero-contents-container,
  .about-container {
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: center;
  }
  .hero-contents-container .text-side,
  .hero-contents-container .image-side,
  .about-text-side {
    width: 100%;
    max-width: 700px;
    text-align: center;
  }
  .about-text-side {
    max-width: 600px;
    text-align: justify;
    margin: auto;
  }
  .about-text-side h4 {
    text-align: center;
  }
   .accepted-payments p{
    text-align: left;
  }
  .hero-contents-container .image-side,
  .about-image-side {
    order: -1;
    padding-top: 0;
    margin: auto;
  }
  .about-image-side {
    order: 1;
  }
  .hero-contents-container .image-side img,
  .about-image-side img {
    width: 400px;
  }
  .text-side-cta {
    margin: 60px 0 40px 0;
    justify-content: space-between;
  }
  .hero-rating {
    justify-content: center;
  }
  .width-960,
  .width-800 {
    width: 100%;
    max-width: 768px;
  }
  .carousel .slider-items-container.center-slide-arrows .slick-arrow {
    top: 100% !important;
  }
  .carousel .slider-items-container .slick-next {
    right: 20px;
  }

  .carousel .slider-items-container .slick-prev {
    left: 20px;
  }
  .outbook-card {
    flex-flow: column;
  }
  .outbooks-cards-container {
    align-items: end;
  }
  .text-side-review {
    width: 80%;
  }
  .carousel .slider-items-container.hero-review-slide .slick-arrow {
    top: 90% !important;
  }

  .carousel .slider-items-container.hero-review-slide .slick-next {
    right: 0;
  }

  .carousel .slider-items-container.hero-review-slide .slick-prev {
    left: 0;
  }
  .hero-contents-container .image-side img,
  .about-image-side img {
    max-width: 120px;
    width: 100%;
  }
  .hero-title {
    font-size: 2em;
  }
  .hero-subtitle {
    font-size: 14px;
    width: 70%;
    margin: auto;
  }
  .hero-contents-container,
  .about-image-side img {
    width: 500px;
    margin: auto;
  }
  .text-side-cta {
    margin: 40px 0 40px 0;
    justify-content: space-between;
  }
  .site-menu li a {
    font-size: 12px;
  }
  .review-card {
    width: 40%;
  }
}
@media screen and (min-width: 600px) and (max-width: 799px) {
  .nav {
    width: 36px;
    position: relative;
    border: 1px solid #ffdb00;
  }
  .hero-media {
    width: 90%;
  }
  .bundles {
    flex-flow: column;
    flex-wrap: wrap;
    align-items: center;
  }
  .bundle-card {
    padding: 0;
    margin: 32px 0;
  }
  .bundle-card.premium {
    margin-bottom: 30px;
  }
  .nav-label {
    display: block;
    color: #ffdb00;
    font-weight: 600;
    padding: 1px;
    padding-top: 1px;
    padding-top: 1px;
    font-size: 35px;
    line-height: 0;
    height: 34px;
    padding-top: 16px;
    border-radius: 2px;
    cursor: pointer;
    top: 0;
  }
  .nav-inner {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    flex-flow: column;
    width: 250px;
    z-index: 100;
    background-color: #21334d;
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 0 0 2px 2px;
  }

  .nav-inner .site-main-cta {
    width: 100%;
    margin: auto;
  }
  .site-menu {
    width: 100%;
    display: flex;
    flex-flow: column;
    margin-bottom: 20px;
  }
  .site-menu li,
  .site-menu li:last-child {
    padding: 10px 0;
    margin: 10px 0;
    border-bottom: 1px solid rgba(86, 103, 122, 1);
  }
  .cta-button-container,
  .site-menu li {
    width: 100%;
  }
  .hide-menu {
    display: flex;
  }

  .showParent {
    display: flex;
    animation: animatezoom 1s;
  }

  .hero-contents-container,
  .about-container {
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: center;
  }
  .hero-contents-container .text-side,
  .hero-contents-container .image-side,
  .about-text-side {
    width: 100%;
    max-width: 500px;
    text-align: center;
  }
  .about-text-side {
    max-width: 500px;
    text-align: justify;
    margin: auto;
  }
  .about-text-side h4 {
    text-align: center;
  }
  .hero-contents-container .image-side,
  .about-image-side {
    order: -1;
    padding-top: 0;
    margin: auto;
  }
  .about-image-side {
    order: 1;
  }
  .hero-contents-container,
  .about-image-side img {
    width: 500px;
    margin: auto;
  }
  .text-side-cta {
    margin: 60px 0 40px 0;
    justify-content: space-between;
  }
  .hero-rating {
    justify-content: center;
  }
   .accepted-payments p{
    text-align: left;
  }
  .width-960,
  .width-800 {
    width: 100%;
    max-width: 600px;
  }
  .out-img {
    width: 200px;
  }
  .carousel .slider-items-container.center-slide-arrows .slick-arrow {
    top: 50% !important;
  }
  .carousel .slider-items-container .slick-next {
    right: 20px;
  }

  .carousel .slider-items-container .slick-prev {
    left: 20px;
  }
  .outbook-card {
    flex-flow: column;
    width: 100%;
    max-width: 500px;
  }
  .outbooks-cards-container {
    align-items: end;
  }
  .width-600 {
    width: 98%;
    max-width: 600px;
  }
  /*
.topics-wrap{
    flex-wrap: wrap;
    justify-content: center;
}
*/

  .text-side-review {
    width: 100%;
  }
  .carousel .slider-items-container.hero-review-slide .slick-arrow {
    top: 90% !important;
  }

  .carousel .slider-items-container.hero-review-slide .slick-next {
    right: 0;
  }

  .carousel .slider-items-container.hero-review-slide .slick-prev {
    left: 0;
  }
  .review-card {
    width: 70%;
    margin: 10px;
  }
  .cover2 {
    display: none;
  }
  .hero-contents-container .image-side img,
  .about-image-side img {
    max-width: 120px;
    width: 100%;
  }
  .hero-title {
    font-size: 2em;
  }
  .hero-subtitle {
    font-size: 14px;
    margin: auto;
    width: 70%;
  }
  .hero-contents-container,
  .about-image-side img {
    width: 500px;
    margin: auto;
  }
  .text-side-cta {
    margin: 40px 0 40px 0;
    justify-content: space-between;
  }
}
@media screen and (max-width: 599px) {
  .container {
    padding: 0 0.5em;
  }
  .hero-media {
    width: 100%;
  }
  .bundles {
    flex-flow: column;
    flex-wrap: wrap;
    align-items: center;
  }
  .bundle-card.premium {
    margin-bottom: 30px;
  }
  .bundle-images-container figure img {
    width: 100px;
  }
  .bundle-card {
    padding: 0;
    margin: 32px 0;
  }
  .bundle-images-container figure {
    padding: 8px;
  }
  .nav {
    width: 36px;
    position: relative;
    border: 1px solid #ffdb00;
  }
  .width-600 {
    width: 98%;
  }
  .hide-menu {
    display: flex;
  }
  .hide-menu-item {
    display: block;
  }
 .accepted-payments div.flex{
    justify-content: center;
  }
  .sub-cta{
    display: none;
  }
  .nav-label {
    display: block;
    color: #ffdb00;
    font-weight: 600;
    padding: 1px;
    padding-top: 1px;
    padding-top: 1px;
    font-size: 35px;
    line-height: 0;
    height: 34px;
    padding-top: 16px;
    border-radius: 2px;
    cursor: pointer;
  }
  .nav-inner {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    flex-flow: column;
    width: 250px;
    z-index: 100;
    background-color: #21334d;
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 0 0 2px 2px;
  }

  .nav-inner .site-main-cta {
    width: 100%;
    margin: auto;
  }
  .site-menu {
    width: 100%;
    display: flex;
    flex-flow: column;
    margin-bottom: 20px;
  }

  .site-menu li,
  .site-menu li:last-child {
    padding: 10px 0;
    margin: 10px 0;
    border-bottom: 1px solid rgba(86, 103, 122, 1);
  }
  .cta-button-container,
  .site-menu li {
    width: 100%;
  }

  .showParent {
    display: flex;
    animation: animatezoom 1s;
  }

  .hero-contents-container,
  .about-container {
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: center;
  }
  .hero-contents-container .text-side,
  .hero-contents-container .image-side,
  .about-text-side {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
  .about-text-side {
    max-width: 500px;
    text-align: justify;
    margin: auto;
  }
  .about-text-side h4 {
    text-align: center;
  }
  .hero-contents-container .image-side,
  .about-image-side {
    order: -1;
    padding-top: 50px;
    margin: auto;
  }
  .about-image-side {
    order: 1;
  }
  .hero-contents-container .image-side img,
  .about-image-side img {
    max-width: 120px;
    width: 100%;
  }
  .out-img,
  .open-img,
  .sides-image img {
    width: 150px;
  }
  .main-cta .modal-trigger {
    margin: 0;
    margin: auto;
    margin-bottom: 10px;
    width: 95%;
  }
  .text-side-cta {
    margin: 20px 0;
    margin-top: 30px;
    justify-content: center;
    flex-flow: column;
    align-items: initial;
  }
  .text-side-cta button {
    width: 95%;
    margin: auto;
    margin-bottom: 20px;
  }
  .hero-title {
    font-size: 2em;
    margin-bottom: 5px;
  }
  .hero-subtitle {
    font-size: 14px;
    width: 70%;
    margin: auto;
  }
  .hero-offer {
    text-align: center;
  }
  .modal-title {
    font-size: 1.3em;
  }
  .hero-rating {
    justify-content: center;
  }
  .width-960,
  .width-800 {
    width: 100%;
  }
  .section-head.width-300 {
    width: 100%;
    max-width: 380px;
  }
  .carousel .slider-items-container.center-slide-arrows .slick-arrow {
    top: 105% !important;
  }
  .carousel .slider-items-container .slick-next {
    right: 20px;
  }

  .carousel .slider-items-container .slick-prev {
    left: 20px;
  }
  .outbook-card {
    flex-flow: column;
    width: 100%;
    max-width: 500px;
  }
  .outbooks-cards-container {
    align-items: end;
  }

  .social-container {
    flex-flow: column;
  }
  .footer-menu {
    flex-wrap: wrap;
  }
  .social-card {
    margin: 20px auto;
  }

  .main-book {
    order: -1;
    width: 100%;
  }
  .footer-menu-item li {
    width: 100%;
  }
  .site-book {
    text-align: center;
    max-width: 300px;
    width: 100%;
    margin: auto;
  }
  .hero-review {
    margin: 0;
    margin-top: 10px;
  }

  .scroll {
    bottom: 10px;
    right: 5px;
  }

  .modal-close-button {
    width: auto !important;
    margin-right: 0 !important;
  }
  .text-side-review {
    width: 95%;
  }
  .carousel .slider-items-container.hero-review-slide .slick-arrow {
    top: 105% !important;
  }

  .carousel .slider-items-container.hero-review-slide .slick-next {
    right: 0;
  }

  .carousel .slider-items-container.hero-review-slide .slick-prev {
    left: 0;
  }

  .carousel .slider-items-container.hero-review-slide .slick-dots {
    bottom: 0 !important;
    top: 100% !important;
  }
  .tltlogo {
    width: 32px;
  }
  .contact-container {
    flex-flow: wrap;
  }
  .hero-contents-container {
    padding-top: 0;
  }
  .hero-contents-container .image-side {
    padding-top: 0;
  }
  .header {
    height: 52px;
  }
  .review-card {
    width: 100%;
  }
}
